import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { Wrapper, PolicyDocument } from './style';
import 'react-image-lightbox/style.css';

const Privacy = ({ lang }) => {
    const [content, setContent] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);

        const url =
            window.location.pathname === '/it/privacy' || window.location.pathname === '/en/privacy'
                ? 'https://www.iubenda.com/api/privacy-policy/61425600'
                : 'https://www.iubenda.com/api/privacy-policy/61425600/cookie-policy';

        fetch(url)
            .then(response => response.json())
            .then(({ success, content: policy }) => success && setContent(policy));
    }, []);

    console.log(content);

    return (
        <Wrapper>
            <Container>
                <PolicyDocument>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </PolicyDocument>
            </Container>
        </Wrapper>
    );
};

export default Privacy;
