import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 196px 64px 128px 64px;
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.secondary.dark};

    h6,
    p {
        color: ${({ theme }) => theme.palette.secondary.dark};
    }

    h3 {
        color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

export const PolicyDocument = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin: 0 auto;
    background: white;
    padding: 48px 64px;
    border-radius: 24px;
    box-sizing: border-box;

    p:last-child {
        margin: 0;
    }
`;
